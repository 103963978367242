import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
Lasr Updated: June 6, 2021
    </PageDescription>
    <ol>
      <li parentName="ol">{`Introduction`}</li>
    </ol>
    <p>{`Welcome to Deep EcomInsight Pvt Ltd. (“Company”, “we”, “our”, “us”)! As you have just clicked our Terms of Service, please pause,grab a cup of coffee and carefully read the following pages. It will take you approximately 20 minutes.`}</p>
    <p>{`These Terms of Service (“Terms”, “Terms of Service”) govern your use of our web pages located at `}<a parentName="p" {...{
        "href": "https://deepinsight.tech/"
      }}>{`https://deepinsight.tech/`}</a>{` operated by Deep EcomInsight Pvt Ltd.  `}</p>
    <p>{`Our Privacy Policy also governs your use of our Service and explains how we collect, safeguard and disclose information that results from your use of our web pages. Please read it here `}<a parentName="p" {...{
        "href": "https://www.deepinsight.tech/privacy-policy"
      }}>{`https://www.deepinsight.tech/privacy-policy`}</a>{`.`}</p>
    <p>{`Your agreement with us includes these Terms and our Privacy Policy (“Agreements”). You acknowledge that you have read and understood Agreements, and agree to be bound of them.`}</p>
    <p>{`If you do not agree with (or cannot comply with) Agreements, then you may not use the Service, but please let us know by emailing at `}<a parentName="p" {...{
        "href": "mailto:info@deepinsight.tech"
      }}>{`info@deepinsight.tech`}</a>{` so we can try to find a solution. These Terms apply to all visitors, users and others who wish to access or use Service.`}</p>
    <p>{`Thank you for being responsible.`}</p>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Communications`}</li>
    </ol>
    <p>{`By creating an Account on our Service, you agree to subscribe to newsletters, marketing or promotional materials and other information we may send. However, you may opt out of receiving any, or all, of these communications from us by following the unsubscribe link or by emailing at.`}</p>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Purchases`}</li>
    </ol>
    <p>{`If you wish to purchase any product or service made available through Service (“Purchase”), you may be asked to supply certain information relevant to your Purchase including,without limitation, your credit card number, the expiration date of your credit card, your billing address, and your shipping information.`}</p>
    <p>{`You represent and warrant that: (i) you have the legal right to use any credit card(s) or other payment method(s) in connection with any Purchase; and that (ii) the information you supply to us is true, correct and complete.`}</p>
    <p>{`We may employ the use of third party services for the purpose of facilitating payment and the completion of Purchases. By submitting your information, you grant us the right to provide the information to these third parties subject to our Privacy Policy.`}</p>
    <p>{`We reserve the right to refuse or cancel your order at any time for reasons including but not limited to: product or service availability, errors in the description or price of the product or service, error in your order or other reasons. We reserve the right to refuse or cancel your order if fraud or an unauthorized or illegal transaction is suspected.  `}</p>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Subscriptions`}</li>
    </ol>
    <p>{`Some parts of Service are billed on a subscription basis (“Subscription(s)”). You will be billed in advance on a recurring and periodic basis (“Billing Cycle”). Billing cycles are set either on a monthly or annual basis, depending on the type of subscription plan you select when purchasing a Subscription.`}</p>
    <p>{`At the end of each Billing Cycle, your Subscription will automatically renew under the exact same conditions unless you cancel it or Deep EcomInsight Pvt Ltd.  cancels it. You may cancel your Subscription renewal either through your online account management page or by contacting Deep EcomInsight Pvt Ltd.  customer support team.`}</p>
    <p>{`A valid payment method, including credit card or UPI, is required to process the payment for your subscription. You shall provide Deep EcomInsight Pvt Ltd.  with accurate and complete billing information including full name, address, state, zip code, telephone number, and a valid payment method information. By submitting such payment information, you automatically authorize Deep EcomInsight Pvt Ltd.  to charge all Subscription fees incurred through your account to any such payment instruments.`}</p>
    <p>{`Should automatic billing fail to occur for any reason, Deep EcomInsight Pvt Ltd.  will issue an electronic invoice indicating that you must proceed manually, within a certain deadline date, with the full payment corresponding to the billing period as indicated on the invoice.`}</p>
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`Free Trial`}</li>
    </ol>
    <p>{`Deep EcomInsight Pvt Ltd. may, at its sole discretion, offer a Subscription with a free trial for a limited period of time (“Free Trial”).`}</p>
    <p>{`You may be required to enter your billing information in order to signup for Free Trial.`}</p>
    <p>{`If you do enter your billing information when signing up for Free Trial,you will not be charged by Deep EcomInsight Pvt Ltd.  until Free Trial has expired. On the last day of Free Trial period, unless you cancelled your Subscription, you will be automatically charged the applicable Subscription fees for the type of Subscription you have selected.`}</p>
    <p>{`At any time and without notice, Deep EcomInsight Pvt Ltd.  reserves the right to (i) modify Terms of Service of Free Trial offer, or (ii) cancel such Free Trial offer.`}</p>
    <ol {...{
      "start": 6
    }}>
      <li parentName="ol">{`Fee Changes`}</li>
    </ol>
    <p>{`Deep EcomInsight Pvt Ltd. , inits sole discretion and at any time, may modify Subscription fees for the Subscriptions. Any Subscription fee change will become effective at the end of the then-current Billing Cycle.`}</p>
    <p>{`Deep EcomInsight Pvt Ltd.  will provide you with a reasonable prior notice of any change in Subscription fees to give you an opportunity to terminate your Subscription before such change becomes effective.`}</p>
    <p>{`Your continued use of Service after Subscription fee change comes into effect constitutes your agreement to pay the modified Subscription fee amount.`}</p>
    <ol {...{
      "start": 7
    }}>
      <li parentName="ol">{`Refunds`}</li>
    </ol>
    <p>{`Except when required by law, paid Subscription fees are non-refundable.`}</p>
    <ol {...{
      "start": 8
    }}>
      <li parentName="ol">{`Content`}</li>
    </ol>
    <p>{`Our Service allows you to post, link, store, share and otherwise make available certain information, text, graphics, videos, or other material (“Content”). You are responsible for Content that you post on or through Service, including its legality,reliability, and appropriateness.`}</p>
    <p>{`By posting Content on or through Service, You represent and warrant that: (i) Content is yours (you own it) and/or you have the right to use it and the right to grant us the rights and license as provided in these Terms, and(ii) that the posting of your Content on or through Service does not violate the privacy rights, publicity rights, copyrights, contract rights or any other rights of any person or entity. We reserve the right to terminate the account of anyone found to be infringing on a copyright.`}</p>
    <p>{`You retain any and all of your rights to any Content you submit, post or display on or through Service and you are responsible for protecting those rights. We take no responsibility and assume no liability for Content you or any third party posts on or through Service. However, by posting Content using Service you grant us the right and license to use, modify, publicly perform,publicly display, reproduce, and distribute such Content on and through Service. You agree that this license includes the right for us to make your Content available to other users of Service, who may also use your Content subject to these Terms.`}</p>
    <p>{`Deep EcomInsight Pvt Ltd. has the right but not the obligation to monitor and edit all Content provided by users.`}</p>
    <p>{`In addition, Content found on or through this Service are the property of Deep EcomInsight Pvt Ltd.  or used with permission. You may not distribute, modify, transmit, reuse, download, repost, copy, or use said Content, whether in whole or in part, for commercial purposes or for personal gain, without express advance written permission from us.`}</p>
    <ol {...{
      "start": 9
    }}>
      <li parentName="ol">{`Prohibited Uses`}</li>
    </ol>
    <p>{`You may use Service only for lawful purposes and in accordance with Terms. You agree not to use Service:
(a)   In any way that violates any applicable national or international law or regulation.
(b)   For the purpose of exploiting, harming,or attempting to exploit or harm minors in any way by exposing them to inappropriate content or otherwise.
(c)   To transmit, or procure the sending of,any advertising or promotional material, including any “junk mail”, “chain letter,” “spam,” or any other similar solicitation.
(d)   To impersonate or attempt to impersonate Company, a Company employee, another user, or any other person or entity.
(e)   In any way that infringes upon the rights of others, or in any way is illegal, threatening, fraudulent, or harmful,or in connection with any unlawful, illegal, fraudulent, or harmful purpose or activity.
(f)     To engage in any other conduct that restricts or inhibits anyone’s use or enjoyment of Service, or which, as determined by us, may harm or offend Company or users of Service or expose them to liability.`}</p>
    <p>{`Additionally, you agree not to:
(a)   Use Service in any manner that could disable, overburden, damage, or impair Service or interfere with any other party’s use of Service, including their ability to engage in real time activities through Service.
(b)  Use any robot, spider, or other automatic device, process, or means to access Service for any purpose, including monitoring or copying any of the material on Service.
(c)  Use any manual process to monitor or copy any of the material on Service or for any other unauthorized purpose without our prior written consent.
(d)  Use any device, software, or routine that interferes with the proper working of Service.
(e)  Introduce any viruses, trojan horses, worms, logic bombs, or other material which is malicious or technologically harmful.
(f)    Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of Service, the server on which Service is stored, or any server, computer, or database connected to Service.
(g)  Attack Service via a denial-of-service attack or a distributed denial-of-service attack.
(h)  Take any action that may damage or falsify Company rating.
(i)    Otherwise attempt to interfere with the proper working of Service.`}</p>
    <ol {...{
      "start": 10
    }}>
      <li parentName="ol">{`Analytics`}</li>
    </ol>
    <p>{`‍We may use third-party Service Providers to monitor and analyze the use of our Service.`}</p>
    <p>{`Google Analytics
Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google uses the data collected to track and monitor the use of our Service. This data is shared with other Google services. Google may use the collected data to contextualise and personalise the ads of its own advertising network.`}</p>
    <p>{`For more information on the privacy practices of Google, please visit the Google Privacy Terms web page: `}<a parentName="p" {...{
        "href": "https://policies.google.com/privacy?hl=en"
      }}>{`https://policies.google.com/privacy?hl=en`}</a></p>
    <p>{`We also encourage you to review the Google’s policy for safeguarding your data: `}<a parentName="p" {...{
        "href": "https://support.google.com/analytics/answer/6004245"
      }}>{`https://support.google.com/analytics/answer/6004245`}</a>{`.  `}</p>
    <ol {...{
      "start": 11
    }}>
      <li parentName="ol">{`No Use By Minors`}</li>
    </ol>
    <p>{`‍Service is intended only for access and use by individuals at least eighteen (18) years old. By accessing or using any of Company, you warrant and represent that you are at least eighteen (18) years of age and with the full authority, right, and capacity to enter into this agreement and abide by all of the terms and conditions of Terms. If you are not at least eighteen (18) years old, you are prohibited from both the access and usage of Service.`}</p>
    <ol {...{
      "start": 12
    }}>
      <li parentName="ol">{`Accounts`}</li>
    </ol>
    <p>{`When you create an account with us, you guarantee that you are above the age of 18, and that the information you provide us is accurate, complete, and current at all times. Inaccurate, incomplete, or obsolete information may result in the immediate termination of your account on Service.`}</p>
    <p>{`You are responsible for maintaining the confidentiality of your account and password, including but not limited to the restriction of access to your computer and/or account. You agree to accept responsibility for any and all activities or actions that occur under your account and/or password, whether your password is with our Service or a third-party service. You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account.`}</p>
    <p>{`You may not use as a username the name of another person or entity or that is not lawfully available for use, a name or trademark that is subject to any rights of another person or entity other than you, without appropriate authorization. You may not use as a username any name that is offensive, vulgar or obscene.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      